import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Promocionone = () => {
  const [ofertas, setOfertas] = useState([]);
  const [vencidos, setVencidos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch API
  useEffect(() => {
    const fetchPromociones = async () => {
      try {
        const response = await fetch('https://swserviceapp.mac-softins.com/api/showcatalogopromociones');
        if (!response.ok) throw new Error('Error al obtener datos de las promociones');
        const data = await response.json();

        setOfertas(data.ofertas); // Guardar las promociones activas
        setVencidos(data.vencidos); // Guardar las promociones vencidas
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchPromociones();
  }, []);

  if (isLoading) return <p className="loading-cardCTI">Cargando promociones...</p>;
  if (error) return <p className="error-cardCTI">{error}</p>;

  return (
    <div className="catalog-container-cardCTI">
      <h2 className="text-center-cardCTI">Nuestros Catálogos De Ofertas</h2>

      {/* Mostrar promociones activas */}
      <div className="promociones-grid-cardCTI">
        {ofertas.length > 0 ? (
          ofertas.map((promocion) => (
            <Link to={`/viewpromocatunic/${promocion.id_cat}`} key={promocion.id_cat} className="card-cardCTI">
              <div>
                <div className="offer-container">
                  <img
                    src="https://www.nsvida.com/wp-content/uploads/2020/08/super-promocion.png"
                    className="offer-image-special"
                    alt="Oferta especial"
                  />
                </div>
                <img src={promocion.image} alt={promocion.nombre} className="card-img-cardCTI" />
                <div className="card-content-cardCTI">
                  <h4 className="promo-nombre-cardCTI">{promocion.nombre}</h4>
                  <p className="promo-precio-cardCTI">Cantidad de Promociones: {promocion.total}</p>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p className="text-center">No hay promociones activas disponibles.</p>
        )}
      </div>

      {/* Mostrar promociones vencidas */}
      <h2 className="text-center-cardCTI">Promociones Vencidas</h2>
      <hr></hr>
      <div className="promociones-grid-cardCTI">
        {vencidos.length > 0 ? (
          vencidos.map((promocion) => (
            <Link to={`/viewpromocatunic/${promocion.id_cat}`} key={promocion.id_cat} className="card-cardCTI">
              <div key={promocion.id_cat} className="card-cardCTI">
                <div>
                  <div className="offer-container">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/5581/5581184.png"
                      className="offer-image-special"
                      alt="Promoción vencida"
                    />
                  </div>
                  <img src={promocion.image} alt={promocion.tipo} className="card-img-cardCTI" />
                  <div className="card-content-cardCTI">
                    <h4 className="promo-nombre-cardCTI">{promocion.tipo}</h4>
                    <p className="promo-precio-cardCTI">Cantidad de Promociones: {promocion.total}</p>
                    <p className="promo-fechas-cardCTI">
                      {`Válida desde: ${new Date(promocion.fecha_ini).toLocaleDateString()} hasta: ${new Date(promocion.fecha_fin).toLocaleDateString()}`}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p className="text-center">No hay promociones vencidas disponibles.</p>
        )}
      </div>
    </div>
  );
};

export default Promocionone;
