import React, { useState, useEffect } from 'react';

const Homenoticia = () => {
  const [isImageEnlarged, setIsImageEnlarged] = useState(false); // Estado para saber si la imagen está ampliada
  const [enlargedImageUrl, setEnlargedImageUrl] = useState(''); // Estado para almacenar la URL de la imagen ampliada
  // Estado para almacenar las noticias
  const [noticias, setNoticias] = useState([]);
  // Función para ampliar la imagen
  const enlargeImage = (url) => {
    setEnlargedImageUrl(url);
    setIsImageEnlarged(true);
  };

  // Función para cerrar la imagen ampliada
  const closeEnlargedImage = () => {
    setIsImageEnlarged(false);
    setEnlargedImageUrl('');
  };
  // Llamada a la API para obtener las noticias
  useEffect(() => {
    const fetchNoticias = async () => {
      try {
        const response = await fetch('https://swserviceapp.mac-softins.com/api/sectionnoticiashome');
        const data = await response.json();
        setNoticias(data);
      } catch (error) {
        console.error('Error al obtener las noticias:', error);
      }
    };

    fetchNoticias();
  }, []);

  return (
    <section className="noticias-section">

      <div className="container_noticias">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="section-title text-center">
            <h2>Noticias Relevantes de M@c-Softins </h2>
            <div className="divider mx-auto my-4"></div>
          </div>
        </div>
      </div>
        <div className="row_noticiahome">
          {noticias.length > 0 ? (
            noticias.map((noticia) => (
              <div className="col-lg-4 col-md-6 mb-4" key={noticia.id}>
                <div className="card noticia-card">
                  <img src={noticia.url}   onClick={() => enlargeImage(noticia.url)} alt={noticia.titulo} className="card-img-top noticia-img" />
                  <div className="card-body">
                    <h5 className="card-title">{noticia.titulo}</h5>
                    <p className="card-text">{noticia.name}</p>
                    <a href={`/Viewnoticia/${noticia.id}`} className="btn btn-primary btn-block btn-rounded">
                      Leer más <i className="icofont-hand-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Cargando noticias...</p>
          )}
        </div>
      </div>
      {isImageEnlarged && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Fondo opaco
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 2000,
          }}
          onClick={closeEnlargedImage} // Cerrar al hacer clic fuera de la imagen
        >
          <img
            src={enlargedImageUrl}
            alt="Código QR Ampliado"
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              objectFit: 'contain', // Aseguramos que la imagen se mantenga dentro del contenedor
            }}
          />
        </div>
      )}
    </section>
  );
};

export default Homenoticia;
