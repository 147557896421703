import React from 'react';
import { useNavigate } from 'react-router-dom';

const FloatingButton = () => {
  const navigate = useNavigate(); // Usamos el hook useNavigate para cambiar la ruta

  const handleClick = () => {
    navigate('/promociones'); // Redirige a la ruta '/promociones'
  };

  return (
    <div
      style={{
        position: 'fixed',  // Posición fija
        top: '15%',  // Establecer en la parte superior de la pantalla
        right: '2%',  // Establecer en la esquina derecha
        backgroundColor: '#223a6673',  // Color inicial de fondo
        padding: '5px',  // Relleno del botón
        borderRadius: '50%',  // Forma circular
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',  // Sombra
        cursor: 'pointer',  // Cambia el cursor al pasar sobre el botón
        zIndex: '1000',  // Asegura que el botón esté por encima de otros elementos
        animation: 'pulse 1.5s infinite', // Efecto de pulsación
      }}
      onClick={handleClick}  // Acción del botón, redirige a promociones
    >
      <img
        src="https://www.pngkey.com/png/full/238-2388837_mejores-ofertas.png"
        alt="Botón flotante"
        style={{
          width: '80px',  // Ancho de la imagen
          height: '80px',  // Alto de la imagen
          borderRadius: '50%',  // Imagen circular
        }}
      />
    </div>
  );
};

export default FloatingButton;
