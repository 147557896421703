import React, { useEffect, useState } from 'react';

const Games = () => {
  const [games, setGames] = useState([]);
  const [expanded, setExpanded] = useState({}); // Estado para manejar qué tarjeta está expandida

  // Función para obtener datos de la API
  const fetchGames = async () => {
    try {
      const response = await fetch('https://swserviceapp.mac-softins.com/api/showgamelist');
      const data = await response.json();
      setGames(data); // Guardar los datos en el estado
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  // Llamar a la API al montar el componente
  useEffect(() => {
    fetchGames();
  }, []);

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const cardStyle = {
    background: '#f9f9f9',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '15px',
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'auto', // Ajusta la altura de forma automática
    transition: 'height 0.3s ease', // Añade transición suave para el cambio de tamaño
  };

  const imageStyle = {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '5px',
    marginBottom: '10px',
  };

  const truncateText = (text, length = 100) =>
    text.length > length ? `${text.substring(0, length)}...` : text;

  return (
    <div className="ceo-page">
      <section className="ceo-introduction">
        <div className="container">
          <h1 className="text-center">Conozca nuestro Sector de Juegos</h1>
          <p className="text-center">
          Ingresa a cada juego de tu preferencia y vive experiensias unicas
          </p>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
              gap: '20px',
              marginTop: '20px',
            }}
          >
            {games.map((game) => (
              <div
                key={game.id}
                style={cardStyle}
              >
                <img src={`https://rath.mac-softins.com/${game.image}`} alt={game.nombre} style={imageStyle} />
                <h3>{game.nombre}</h3>
                <p>
                  {expanded[game.id] ? game.descripcion : truncateText(game.descripcion)}
                </p>
                <button
                  onClick={() => toggleExpand(game.id)}
                  style={{
                    backgroundColor: 'rgb(34 58 102)',
                    color: '#fff',
                    border: 'none',
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    marginTop: '10px',
                  }}
                >
                  {expanded[game.id] ? 'Ver menos' : 'Ver más'}
                </button>
                <div className="card-footer">
                  {game.enlace && (
                    <a
                      href={game.enlace}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: 'inline-block',
                        marginTop: '10px',
                        padding: '10px 15px',
                        backgroundColor:  'rgb(34 58 102)',
                        color: '#fff',
                        borderRadius: '5px',
                        textDecoration: 'none',
                      }}
                    >

                    <i className="icofont-game-controller"></i> Ingresar al juego
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Games;
