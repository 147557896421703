import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

const Client = () => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    // Fetch de datos desde la API
    fetch('https://swserviceapp.mac-softins.com/api/showmarcalist')
      .then((response) => response.json())
      .then((data) => {
        // Asignamos los datos al estado de brands
        setBrands(data);
      })
      .catch((error) => console.error('Error fetching brands:', error));
  }, []);

  // Configuración del slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="section clients">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h2>Trabajamos con las marcas más reconocidas en el mercado</h2>
              <div className="divider mx-auto my-4"></div>
              <p>
                Trabajamos con las mejores marcas del mercado, garantizando productos y servicios de alta calidad que cumplen con los más altos estándares.
                Nuestra colaboración con líderes de la industria nos permite ofrecer soluciones
                innovadoras y confiables que se adaptan a las necesidades de nuestros clientes.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-body">
        <Slider {...settings}>
          {brands.map((brand) => (
            <div key={brand.id} className="client-thumb">
            <img
              src={`https://rath.mac-softins.com/${brand.image}`}
              alt={brand.nombre}
              className="img-fluid"
            />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Client;
