import React from 'react';
const Features = () => {
  return (
    <section className="features">
      <div className="container">
        <div className="rowfind">
          <div className="col-lg-12">
            <div className="feature-block d-lg-flex">

              {/* Primer bloque de características */}
              <div className="feature-item mb-5 mb-lg-0">
                <div className="feature-icon mb-4">
                  <i className="icofont-certificate-alt-1"></i>
                </div>
                <span>Servicio 24 Horas</span>
                <h4 className="mb-3">Nuestro CEO</h4>
                <p className="mb-4 emergencia">
                  Conoce mas sobre nuestro CEO y fundador de la empresa contamos  con mas de 10 años de experiencia en el area Sistemas y Desarrollo Aplicaciones
                </p>
                <a href="/ceo" className="btn btn-main btn-round-full">Conoce mas  <i className="icofont-hand-right"></i></a>
              </div>

              {/* Segundo bloque de características */}
              <div className="feature-item mb-5 mb-lg-0">
                <div className="feature-icon mb-4">
                  <i className="icofont-wall-clock"></i>
                </div>
                <span>Horarios de Atencion De Reservas</span>
                <h4 className="mb-3">Horarios de Trabajo</h4>
                <ul className="w-hours list-unstyled">
                  <li className="d-flex justify-content-between">Lunes - Viernes : <span>8:00 - 19:00</span></li>
                  <li className="d-flex justify-content-between">Sabados - Domingos : <span>10:00 - 17:00</span></li>
                </ul>
                <br/>
                <br/>
                <a href="/contact" className="btn btn-main btn-round-full">Agendar <i className="icofont-hand-right"></i></a>
              </div>

              {/* Tercer bloque de características */}
              <div className="feature-item mb-5 mb-lg-0">
                <div className="feature-icon mb-4">
                  <i className="icofont-live-support"></i>
                </div>
                <span>Casos De Emergencia</span>
                <h4 className="mb-3">+591 67916774</h4>
                <p className="emergencia">
                En caso de que no recibas una respuesta oportuna a tus tickets de soporte, por favor, no dudes en contactarnos a nuestro número de emergencia.
                <br />
                Tu satisfacción y tranquilidad son nuestra prioridad, y estamos aquí para ayudarte en cualquier momento que lo necesites.
                </p>

              </div>
              {/* Tercer bloque de características */}
              <div className="feature-item mb-5 mb-lg-0">
                <div className="feature-icon mb-4">
                  <i className="icofont-game-controller"></i>
                </div>
                <span>Sector De Juegos</span>
                <h4 className="mb-3">Juega y participa de las Promociones</h4>
                <p className="emergencia">
                  ¡Juega, diviértete y gana con nuestras promociones! Participa para obtener increíbles premios mientras disfrutas de tus juegos favoritos.
                  Cada promoción trae sorpresas únicas, desde descuentos hasta regalos exclusivos. ¡No pierdas la oportunidad de ganar mientras te diviertes!
                <br />
                </p>
                  <a href="/games" className="btn btn-main btn-round-full">Ingresar <i className="icofont-hand-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
