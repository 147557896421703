import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import "../../App.css";
const apiUrl = process.env.REACT_APP_API_URL;

const Viewcatalogopromoview = () => {
  const { id } = useParams();
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const response = await fetch(apiUrl + `showpromoscatalogoview/${id}`);
        if (!response.ok) throw new Error('Error al obtener los detalles del catálogo');
        const data = await response.json();

        setCategoryDetails(data); // Asignamos los datos recibidos a categoryDetails
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryDetails();
  }, [id]);

  if (loading) {
    return <p>Cargando datos...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <section className="s_features_section_vps">
        <div className="container">
          <h3 className="text-center">Promocion Valida Desde El {categoryDetails.fecha_inicio} al {categoryDetails.fecha_fin}</h3>
          <hr />
          <div className="rowit separation">
            {/* Listado de planes */}
            {categoryDetails.productos.map((plan, index) => (
              
              <div key={plan.id || index} className="card">
                {/* Imagen de Oferta */}
                <div className="offer-container">
                  <img
                    src="https://www.nsvida.com/wp-content/uploads/2020/08/super-promocion.png"
                    alt="Oferta especial"
                    className="offer-image-special"
                  />
                </div>

                <div className="car-img">
                  <figure>
                    <img
                      loading="lazy"
                      decoding="async"
                      src={"https://rath.mac-softins.com/" + plan.image}
                      alt="Promoción"
                      className="plan-image"
                    />
                  </figure>
                </div>
                <div className="plan-details">
                  <h2 className="titulos_servicios f_p f_size_20 f_700 t_color l_height45 wow fadeInRight">
                    {plan.nombre}
                  </h2>
                </div>

                <div className="descripcion">
                  <span className="badge badge_anual">
                    {plan.monto} Bs.
                  </span>
                  <div className="descripcion" dangerouslySetInnerHTML={{ __html: plan?.descripcion || '' }} />
                </div>

                {/* Footer con botones */}
                <div className="card-footer">
                  {/* Botón "Ver Video" solo si hay un enlace de video */}
                  {plan.video ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => window.open(plan.video, "_blank")}
                    >
                    <i className="fa-solid fa-globe"></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      disabled
                    >
                      No Disponible
                    </button>
                  )}

                  {/* Botón "Ver Enlace" solo si hay un enlace disponible */}
                  {plan.enlace ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => window.open(plan.enlace, "_blank")}
                    >
                      <i className="fa fa-video"></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-secondary"
                      disabled
                    >
                      No Disponible
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Viewcatalogopromoview;
