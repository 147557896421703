import React, { useState, useEffect } from 'react';

const brands = [
  {
    id: 1,
    nombre: 'Marca 1',
    imagen: 'https://uatf.edu.bo/assets/images/academy/programa_becas.webp',
  },
  {
    id: 2,
    nombre: 'Marca 2',
    imagen: 'https://uatf.edu.bo/assets/images/academy/programa_becas.webp',
  },
  {
    id: 3,
    nombre: 'Marca 3',
    imagen: 'https://uatf.edu.bo/assets/images/academy/programa_becas.webp',
  },
];

const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Manejo del cambio de diapositiva automáticamente cada 3 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % brands.length);
    }, 3000); // Cambia la diapositiva cada 3 segundos

    return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
  }, []);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % brands.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + brands.length) % brands.length);
  };

  return (
    <section className="banner">
      {/* Video de fondo */}
      <video autoPlay muted loop className="banner-video">
        <source src="/video.webm" type="video/webm" />
        Your browser does not support the video tag.
      </video>

      {/* Contenido principal */}
      <div className="banner-content">
        <div className="container">
          <div className="row">
            {/* Primera columna: Mensaje */}
            <div className="col-6">
              <div className="block">
                <div className="divider mb-3"></div>
                <span className="text-uppercase text-sm letter-spacing">
                  Expertos en tecnología & asesoramiento
                </span>
                <h1 className="mb-3 mt-3 colorh1">M@C - SOFTINS</h1>
                <p className="mb-4">
                  Consultora de sistemas e Informática y venta de equipamiento Tecnológico
                  <br />
                  Contamos con las mejores marcas
                  <br />
                  Desarrollamos Sistemas y Aplicaciones
                </p>
                <div className="btn-container">
                  <a
                    href="about"
                    rel="noopener noreferrer"
                    className="btn btn-main-2"
                  >
                    Conocer más sobre nosotros
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
