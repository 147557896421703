import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const servicebalanza = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2  className="text-center">Servicio de Mantenimiento y Venta de Balanzas</h2>
      <div style={containerStyle}>
           {/* Sección de la imagen */}
           <div style={imageSectionStyle}>
             <img
               src="https://rath.mac-softins.com/imgpublic/oficialpage/balanza/balnzas.png"
               alt="Balanzas"
               style={imageStyle2}
             />
           </div>

           {/* Sección del texto */}
           <div style={textSectionStyle}>
             <h3 style={textStyle}>
               En M@C-SOFTINS, somos líderes en soluciones integrales para sistemas
               de pesaje en toda Bolivia. Nos especializamos en la instalación,
               mantenimiento y calibración de balanzas industriales y comerciales,
               ofreciendo un servicio profesional respaldado por años de experiencia
               en el sector.
               <br />
               <br />
               Entendemos la importancia de contar con equipos de pesaje precisos y
               confiables para el éxito de su operación, y es por eso que nos
               esforzamos en garantizar que cada equipo funcione de manera óptima y
               eficiente.
               <br />
               <br />
               Trabajamos con un enfoque orientado al cliente, asegurando que
               nuestras soluciones estén completamente alineadas con las necesidades
               de su negocio. Además, utilizamos tecnología de última generación y
               estándares internacionales de calidad para ofrecer un servicio que no
               solo cumple, sino que supera las expectativas.
               <br />
               <br />
               Ya sea que requiera servicios para pequeñas empresas comerciales o
               grandes industrias, nuestro equipo altamente capacitado está listo
               para brindarle soporte técnico, asesoramiento personalizado y una
               atención rápida y eficaz. Con Servicebalanza, usted invierte en
               confianza, precisión y durabilidad, consolidando el éxito y la
               competitividad de su negocio en el mercado boliviano.
             </h3>
           </div>
         </div>

      <h2 className="text-center margin" >Productos & Servicios Destacados</h2>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '20px',
        marginTop: '20px',
      }}>
        {/* Card 1 */}
        <div style={cardStyle}>
          <img
            src="https://www.ipc.com.mx/basculas/basculas-pesaje-industrial/basculas-de-banco.jpg"
            alt="BALANZAS DE MESA Y BANCO"
            style={imageStyle}
          />
          <h3>BALANZAS DE MESA Y BANCO</h3>
          <p>Diseñadas para precisión y durabilidad en entornos comerciales.</p>
        </div>
        {/* Card 2 */}
        <div style={cardStyle}>
          <img
            src="https://m.media-amazon.com/images/I/6124aXDeaQL.jpg"
            alt="BALANZAS DE PISO"
            style={imageStyle}
          />
          <h3>BALANZAS DE PISO</h3>
          <p>Ideales para operaciones industriales y comerciales de alto volumen.</p>
        </div>
        {/* Card 3 */}
        <div style={cardStyle}>
          <img
            src="https://www.basculasmedigan.com/wp-content/uploads/2022/07/WhatsApp-Image-2022-07-18-at-12.39.26-PM.jpeg"
            alt="BÁSCULAS DE CAMIONES"
            style={imageStyle}
          />
          <h3>BÁSCULAS DE CAMIONES</h3>
          <p>Soluciones de pesaje para transporte pesado, precisas y confiables.</p>
        </div>
        {/* Card 4 */}
        <div style={cardStyle}>
          <img
            src="https://www.systecnet.com/fileadmin/public/Redaktion/Bilder/Waagen-Insights/TKS/Fahrzeugwaage-white-tks-720x460.jpg"
            alt="TERMINALES INDUSTRIALES"
            style={imageStyle}
          />
          <h3>TERMINALES INDUSTRIALES</h3>
          <p>Control avanzado y registro eficiente de datos de pesaje.</p>
        </div>
        {/* Card 5 */}
        <div style={cardStyle}>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCIEySYb9Ciuze2GzzyrLP1_uEr4wvXRN0OGghj4nRm5GB4cogOEnH0ETR7UCP3BYfM5s&usqp=CAU"
            alt="MÓDULOS DE PESAJE Y CELDAS DE CARGA"
            style={imageStyle}
          />
          <h3>MÓDULOS DE PESAJE Y CELDAS DE CARGA</h3>
          <p>Componentes de precisión para integraciones de sistemas de pesaje.</p>
        </div>
        {/* Card 6 */}
        <div style={cardStyle}>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTujZjNK8JLWyG1oGVoombRo4QFnPM6FZUO1w&s"
            alt="PESAS PATRÓN"
            style={imageStyle}
          />
          <h3>PESAS PATRÓN</h3>
          <p>Certificadas para calibraciones y verificaciones precisas.</p>
        </div>
        <div style={cardStyle}>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF77Qzg9-r_7Fx2zkeLmrdje3BP_MvdQucRQ&s"
            alt="Repuestos"
            style={imageStyle}
          />
          <h3>REPUESTO DE BALANZAS</h3>
          <p>Repuestos de balanzas Individuales</p>
        </div>
        <div style={cardStyle}>
          <img
            src="https://kyaserv.com.ar/web/wp-content/uploads/2014/09/portfolio-montaje-balanza-camiones-mt-63.jpg"
            alt="Repuestos"
            style={imageStyle}
          />
          <h3>CONSTRUCCION DE BALANZAS</h3>
          <p>Construcion e instalaicon de Balanzas</p>
        </div>
        <div style={cardStyle}>
          <img
            src="https://www.satepesa.com/wp-content/uploads/2024/06/dibujo-basculapesacamiones.png"
            alt="Repuestos"
            style={imageStyle}
          />
          <h3>CALIBRACION DE BALANZAS</h3>
          <p>Contamos con los mejores profecionales para el calibrado de tu balanza</p>
        </div>
        <div style={cardStyle}>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmoFaFKICPezlsMzbj0JJBU48m2hieQymlFQ&s"
            alt="Repuestos"
            style={imageStyle}
          />
          <h3>SOFTWARE ESPECIALIZADO Y PERSONALIZADO PARA BALANZAS</h3>
          <p>Contamos con los mejores desarrolladores de Aplicaciones para personalizar tu balanza</p>
        </div>
      </div>
      <h2 className="text-center margin">Nuestros Clientes</h2>
      <Slider {...sliderSettings} style={{ margin: '20px auto', maxWidth: '80%' }}>
        {clientsData.map((client, index) => (
          <div key={index} style={sliderItemStyle}>
            <img src={client.image} alt={client.name} style={sliderImageStyle} />
            <h4 className="text-center">{client.name}</h4>
          </div>
        ))}
      </Slider>
    </div>
  );
};

// Estilos en línea
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: '20px',
  gap: '20px',
  flexWrap: 'wrap', // Permite que los elementos se ajusten en múltiples líneas
};

const imageSectionStyle = {
  flex: '1',
  textAlign: 'center',
};

const textSectionStyle = {
  flex: '2',
  textAlign: 'justify',
  padding: '20px',
};

const imageStyle2 = {
  width: '100%',
  maxWidth: '400px',
  borderRadius: '8px',
};

const textStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '16px',
  lineHeight: '1.6',
};
// Estilos en línea para las cards e imágenes
const cardStyle = {
  background: '#f9f9f9',
  border: '1px solid #ddd',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  padding: '15px',
  textAlign: 'center',
};

const imageStyle = {
  width: '100%',
  height: '200px',
  objectFit: 'cover',
  borderRadius: '5px',
  marginBottom: '10px',
};
const sliderItemStyle = {
  textAlign: 'center',
  padding: '10px',
};

const sliderImageStyle = {
  width: '150px',
  height: '150px',
  borderRadius: '50%',
  margin: '0 auto',
  objectFit: 'cover',
};
const margin = {
  marginTop: "25px"
};
const clientsData = [
  { name: 'Cooperativa Minera Porco R.L', image: 'https://rath.mac-softins.com/imgpublic/oficialpage/balanza/porcorl.png' },
  { name: 'Cooperativa Minera Huayna Porco R.L', image: 'https://rath.mac-softins.com/imgpublic/oficialpage/balanza/huayna.png' },
  { name: 'Illapa S.A.', image: 'https://rath.mac-softins.com/imgpublic/oficialpage/balanza/illapa.png' },
  { name: 'Empresa Minera Manquiri S.A.', image: 'https://rath.mac-softins.com/imgpublic/oficialpage/balanza/maquiri.png' },
  { name: 'Carniceria el TALA', image: 'https://rath.mac-softins.com/imgpublic/oficialpage/balanza/crn1.jpeg' },
  { name: 'Carniceria La Esmeralda', image: 'https://rath.mac-softins.com/imgpublic/oficialpage/balanza/emr.png' },
  { name: 'Laboratorio Quimico Señor De Manquiri', image: 'https://rath.mac-softins.com/imgpublic/oficialpage/balanza/sdm.png' },
];
export default servicebalanza;
