import React, { useState, useEffect } from 'react';

const Homeblog = () => {
  // Estado para almacenar las noticias
  const [noticias, setNoticias] = useState([]);

  // Llamada a la API para obtener las noticias
  useEffect(() => {
    const fetchNoticias = async () => {
      try {
        const response = await fetch('https://swserviceapp.mac-softins.com/api/sectionbloghome');
        const data = await response.json();
        setNoticias(data);
      } catch (error) {
        console.error('Error al obtener las noticias:', error);
      }
    };

    fetchNoticias();
  }, []);

  return (
    <section className="noticias-section">
      <div className="container_noticias">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="section-title text-center">
            <h2>Ultimos post de M@c-Softins </h2>
            <div className="divider mx-auto my-12"></div>
          </div>
        </div>
      </div>
        <div className="row_noticiahome">
          {noticias.length > 0 ? (
            noticias.map((noticia) => (
              <div className="col-lg-4 col-md-6 mb-4" key={noticia.id}>
                <div className="card noticia-card">
                  <img src={noticia.imagen} alt={noticia.titulo} className="card-img-top noticia-img" />
                  <div className="card-body">
                    <p className="card-text">{noticia.nombre}</p>
                    <a href={`/blog/${noticia.id}`} className="btn btn-primary btn-block btn-rounded">
                      Leer más <i className="icofont-hand-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Cargando noticias...</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Homeblog;
