import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import RoutesConfig from './routes/RoutesConfig'; // Importa el archivo de rutas
import ModalNoticias from './components/Modal'; // Importa el modal de noticias
import { GlobalProvider } from './context/GlobalContext';
import { Helmet } from 'react-helmet';
//import Snowfall from 'react-snowfall';
import FloatingButton from './components/Oferta';
const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal
  const [noticias, setNoticias] = useState([]); // Estado para almacenar las noticias obtenidas del API

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setIsModalOpen(false); // Cierra el modal
  };


  // Función para consultar los datos del API
  const fetchNoticias = async () => {
    try {
      const response = await fetch('https://swserviceapp.mac-softins.com/api/getmodaldato');
      const data = await response.json();

      if ( data.nombre) {
        setNoticias(data); // Almacena las noticias si hay datos
        setIsModalOpen(true); // Abre el modal automáticamente si hay noticias
      }
    } catch (error) {
      console.error('Error al obtener datos del API:', error);
      // No abrir el modal si hay un error
    }
  };
  const IMAGE_SRC =
    'https://res.cloudinary.com/imphanimurari/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1628421712/Portfolio/WhatsApp_Image_2021-08-08_at_4.50.35_PM_2_bgm46k.jpg'

  // useEffect para consultar los datos al montar el componente
  useEffect(() => {
    fetchNoticias(); // Llama a la función para obtener los datos del API
  }, []); // Solo se ejecuta al montar el componente

  return (

    <GlobalProvider>
    <Helmet>
      <title>M@C - SOFTINS | Desarrollo de Software y Soluciones Tecnológicas</title>

      {/* Descripción Optimizada */}
      <meta name="description" content="M@C-SOFTINS ofrece soluciones tecnológicas innovadoras, desarrollo de software a medida, consultoría en sistemas y ciberseguridad para empresas." />

      {/* Palabras clave relacionadas con tecnología */}
      <meta name="keywords" content="desarrollo de software, soluciones tecnológicas, consultoría en tecnología, ciberseguridad, programación, software a medida, tecnología empresarial, IT solutions" />

      {/* Instrucciones para los motores de búsqueda */}
      <meta name="robots" content="index, follow" />

      {/* URL Canónica para evitar contenido duplicado */}
      <link rel="canonical" href="https://www.mac-softins.com/" />

      {/* Open Graph - Mejora la visibilidad en redes sociales */}
      <meta property="og:title" content="M@C-SOFTINS | Soluciones Tecnológicas y Desarrollo de Software" />
      <meta property="og:description" content="M@C-SOFTINS es tu aliado en el desarrollo de software, ofreciendo soluciones innovadoras y seguras para tu empresa." />
      <meta property="og:image" content="https://www.mac-softins.com/assets/images/logo.png" />
      <meta property="og:url" content="https://www.mac-softins.com/" />
      <meta property="og:type" content="website" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="M@C-SOFTINS | Soluciones Tecnológicas y Desarrollo de Software" />
      <meta name="twitter:description" content="Desarrollamos soluciones tecnológicas a medida, optimizando tus procesos con las mejores prácticas en programación y seguridad." />
      <meta name="twitter:image" content="https://www.mac-softins.com/assets/images/logo.png" />

      {/* Datos Estructurados para mejorar el SEO */}
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9588948888582348"
     crossorigin="anonymous"></script>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "M@C-SOFTINS",
            "url": "https://www.mac-softins.com/",
            "logo": "https://www.mac-softins.com/assets/images/logo.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+59167916774",
              "contactType": "Customer Service"
            },
            "sameAs": [
              "https://www.facebook.com/www.elpitufofelizcom",
              "https://www.linkedin.com/company/mac-softins",
            ]
          }
        `}
      </script>
    </Helmet>

      <Router>
        <div className="App">

          <Header />
          <ModalNoticias isOpen={isModalOpen} onClose={handleCloseModal} noticias={noticias} />
          <RoutesConfig />
          <Footer />
          <FloatingButton />
        </div>
      </Router>
    </GlobalProvider>

  );
};

export default App;
