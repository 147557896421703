import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
const Homecloud = () => {
  const [testimonialsData, setTestimonialsData] = useState([]);

  // Configuración del slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const imageStyle = {
    width: '100%', // Asegura que ocupe todo el ancho del contenedor
    height: '150px', // Define una altura consistente
    textAlign: 'center',
    borderRadius: '8px', // Opcional: esquinas redondeadas
  };
  // Función para obtener los datos del endpoint
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(
          'https://swserviceapp.mac-softins.com/api/sectionCloudhome'
        );
        const data = await response.json();
        setTestimonialsData(data);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    fetchTestimonials();
  }, []);

  return (
    <section className="section-testimonial-2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h2>Los mejores Servicios de Hosting Confiable </h2>
              <div className="divider mx-auto my-4"></div>
              <p>
              🌐 Confía en los Mejores Servicios de Hosting 🚀
                Tu proyecto merece un hosting confiable, rápido y seguro. Con nuestro servicio, tendrás la tranquilidad de contar con tecnología de punta, soporte 24/7 y un rendimiento excepcional. 💼✨
                ¡Haz crecer tu negocio con nosotros y lleva tu presencia online al siguiente nivel! 🌟 Tu éxito empieza aquí.
              </p>
              <div className="text-center" style={{ padding: '1%' }}>
                    <button
                       className="btn btn-main-2"
                       id="btn-round-full-k"
                       style={{ color: '#fff' }}
                       onClick={() => window.location.href = '/Catalogocloud'}
                     >
                      Ver nuestros  Planes
                     </button>
                    </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-body">
        <Slider {...settings}>
          {testimonialsData.map((testimonial, index) => (
            <div className="testimonial-block style-2 gray-bg" key={index}>
              <div className="client-info">
                <img src="https://www.think.es/wp-content/uploads/2020/12/nube-iaas-paas-saas-1152x611.jpg" style={imageStyle} ></img>
                <span>Servidor {testimonial.name}</span>
                <span>{testimonial.monto} Bs.</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>

    </section>
  );
};

export default Homecloud;
