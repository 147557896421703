import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const QRPayment = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [paymentReason, setPaymentReason] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState(''); // Estado para almacenar la URL del QR
  const [qrRegulador, setqrRegulador] = useState(''); // Estado para almacenar la URL del QR Regulador
  const [qrAsfi, setqrAsfi] = useState(''); // Estado para almacenar la URL del QR Asfi
  const [isImageEnlarged, setIsImageEnlarged] = useState(false); // Estado para saber si la imagen está ampliada
  const [enlargedImageUrl, setEnlargedImageUrl] = useState(''); // Estado para almacenar la URL de la imagen ampliada

  // Función para obtener el código QR desde la API
  const fetchQRCode = async () => {
    try {
      const response = await axios.get(apiUrl + 'getqrdataimg');
      const qrPayment = response.data.url + response.data.qrdata.qr;
      const qrRegular = response.data.url + response.data.qrdata.regulado;
      const asfiData = response.data.url + response.data.qrdata.asfi;

      setQrCodeUrl(qrPayment);
      setqrRegulador(qrRegular);
      setqrAsfi(asfiData);
    } catch (error) {
      console.error('Error al obtener el código QR:', error);
      setQrCodeUrl(''); // En caso de error, limpia la URL
    }
  };

  // Usar useEffect para obtener el QR al cargar el componente
  useEffect(() => {
    fetchQRCode();
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleReasonChange = (event) => {
    setPaymentReason(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      setUploadStatus('Por favor, selecciona un archivo antes de enviar.');
      return;
    }

    if (!paymentReason) {
      setUploadStatus('Por favor, ingresa el motivo del pago.');
      return;
    }

    try {
      // Crear FormData para enviar el archivo y el motivo del pago
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('reason', paymentReason);

      // Enviar el formulario al endpoint para guardar el comprobante
      const response = await axios.post(apiUrl + 'savecomprobante', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Verificar la respuesta del servidor
      if (response.status === 200) {
        setUploadStatus('Respaldo enviado correctamente con el motivo del pago.');
        setSelectedFile(null); // Limpiar archivo seleccionado
        setPaymentReason(''); // Limpiar campo de motivo
      } else {
        throw new Error('Error al enviar el comprobante.');
      }
    } catch (error) {
      console.error('Error al enviar el comprobante:', error);
      setUploadStatus('Hubo un error al enviar el comprobante. Por favor, inténtalo de nuevo.');
    }
  };

  // Función para ampliar la imagen
  const enlargeImage = (url) => {
    setEnlargedImageUrl(url);
    setIsImageEnlarged(true);
  };

  // Función para cerrar la imagen ampliada
  const closeEnlargedImage = () => {
    setIsImageEnlarged(false);
    setEnlargedImageUrl('');
  };

  return (
    <section className="qr-payment">
      <div className="qr-payment-container">
        <div className="qr-code">
          {qrCodeUrl ? (
            <img
              src={qrCodeUrl}
              alt="Código QR"
              onClick={() => enlargeImage(qrCodeUrl)} // Hacer clic para ampliar
              style={{
                width: '100%',
                height: '200px',
                objectFit: 'cover',
                borderRadius: '5px',
                marginBottom: '10px',
                cursor: 'pointer',
              }}
            />
          ) : (
            <p>Cargando código QR...</p>
          )}
        </div>

        {/* Ventana emergente para ver imagen ampliada */}
        {isImageEnlarged && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.7)', // Fondo opaco
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 2000,
            }}
            onClick={closeEnlargedImage} // Cerrar al hacer clic fuera de la imagen
          >
            <img
              src={enlargedImageUrl}
              alt="Código QR Ampliado"
              style={{
                maxWidth: '90%',
                maxHeight: '90%',
                objectFit: 'contain', // Aseguramos que la imagen se mantenga dentro del contenedor
              }}
            />
          </div>
        )}
         <div className="upload-form">
              <h3>Subir Respaldo de Pago</h3>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="paymentReason">Motivo del Pago</label>
                  <input
                    type="text"
                    id="paymentReason"
                    value={paymentReason}
                    onChange={handleReasonChange}
                    placeholder="Escribe el motivo del pago"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="fileInput">Seleccionar archivo (PDF, JPG, PNG)</label>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileChange}
                    accept=".pdf,.jpg,.png"
                    required
                  />
                </div>
                <button type="submit" onClick={handleSubmit}  className="btn-submit">Enviar Respaldo</button>
              </form>
              {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
            </div>
      </div>



      {/* Código QR del regulador */}
      <div className="regulador">
        <h3>Contamos con múltiples formas de pago</h3>
        {qrRegulador ? (
          <img
            className="imgregulador"
            src={qrRegulador}
            alt="Regulado"
            onClick={() => enlargeImage(qrRegulador)} // Hacer clic para ampliar
            style={{
              cursor: 'pointer',
              maxWidth: '200px',
              maxHeight: '200px',
              objectFit: 'cover',
            }}
          />
        ) : (
          <p>Cargando código de regulador...</p>
        )}
      </div>

      {/* Código QR de ASFI */}
      <div className="asfi">
        <center>
          <p>
            Pagos con tarjeta, QR, débito directo en BCP, BNB, Tigo Money y Pago Express están supervisados.
          </p>
        </center>
        {qrAsfi ? (
          <img
            className="imgreguladorasfi"
            src={qrAsfi}
            alt="ASFI"
            onClick={() => enlargeImage(qrAsfi)} // Hacer clic para ampliar
            style={{
              cursor: 'pointer',
              maxWidth: '200px',
              maxHeight: '200px',
              objectFit: 'cover',
            }}
          />
        ) : (
          <p>Cargando código ASFI...</p>
        )}
      </div>
    </section>
  );
};

export default QRPayment;
