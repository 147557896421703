import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Banner from '../components/Banner';
import Features from '../components/Features';
import Homenoticia from '../components/Homenoticia';
import HomeBlog from '../components/Homeblog';
import Otroservicio from '../components/Otroservicio';
import About from '../components/About';
import CTA from '../components/CTA';
import Appointment from '../components/Appointment';
import Testimonials from '../components/Testimonials';
import Homeproduct from '../components/Homeproduct';
import Homecloud from '../components/Homecloud';
import Client from '../components/Client';
import Ceo from '../components/Ceo';
import Promocionone from '../components/Promociones';
import Games from '../components/Games';
import Contact from '../components/section/Contact';
import MisionVision from '../components/MisionVision';
import Infraestructura from '../components/section/Infraestructura';
import Ciberseguridad from '../components/section/Ciberseguridad';
import Cloud from '../components/section/Cloud';
import Section from '../components/section/Section';
import Polis from '../components/section/Polis';
import It from '../components/section/It';
import Programacion from '../components/section/Programacion';
import Desarrollo from '../components/section/Desarrollo';
import Section2 from '../components/Section2';
import InfraestructuraIT from '../components/macsoftins/InfraestructuraIT';
import DesarrolloAP from '../components/macsoftins/DesarrolloAP';
import Portafolio from '../components/macsoftins/Portafolio';
import Pageconstrucctor from '../components/macsoftins/Construccion';
import Vercat from '../components/macsoftins/VercatDetail';
import Viewblog from '../components/macsoftins/Blog';
import Viewcatalogo from '../components/macsoftins/Viewcatalogo';
import Viewcatalogopromoview from '../components/macsoftins/Viewcatalogopromoview';
import Noticias from '../components/macsoftins/Noticias';
import Vpshost from '../components/macsoftins/Vpshost';
import Paypal from '../components/macsoftins/Paypal';
import QRPayment from '../components/macsoftins/QRpayment';
import Planvps from '../components/macsoftins/Planvps';
import Plancpanel from '../components/macsoftins/Plancpanel';
import Catalogo from '../components/macsoftins/Catalogo';
import Historia from '../components/macsoftins/Historia';
import ServerDedicated from '../components/macsoftins/ServerDedicated';
import VIIT from '../components/macsoftins/Viit.js';
import Portal360 from '../components/working/360.js';
import Viewcatnoti from '../components/macsoftins/Verareanoticia';
import Viewnoticia from '../components/macsoftins/Viewnoticia';
import ServBB from '../components/macsoftins/servicebalanza';

//Promocionone
import { GlobalContext } from '../context/GlobalContext'; // Importa el contexto
const RoutesConfig = () => {
  const { estadisticas } = useContext(GlobalContext); // Accede al contexto
  return (
    <Routes>
      <Route path="/" element={
        <>
          <Banner />
          <Features />
          <Homenoticia />
          <Otroservicio />
          <HomeBlog />
          <About />
          <CTA estadisticas={estadisticas}/>
          <Testimonials />
          <Homeproduct />
          <Client />
          <Homecloud />
        </>
      } />
      <Route path="/ceo" element={<Ceo />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<MisionVision />} />
      <Route path="/infraestructura" element={<Infraestructura />} />
      <Route path="/ciberseguridad" element={<Ciberseguridad />} />
      <Route path="/servidores-cloud" element={<Cloud />} />
      <Route path="/seguridad-perimetral" element={<Section />} />
      <Route path="/poliza-soporte" element={<Polis />} />
      <Route path="/it" element={<It />} />
      <Route path="/programacion" element={<Programacion />} />
      <Route path="/desarollo-web" element={<Desarrollo />} />
      <Route path="/service-empresarial" element={<Section2 />} />
      <Route path="/InfraestructuraTI" element={<InfraestructuraIT />} />
      <Route path="/DesarrolloApp" element={<DesarrolloAP />} />
      <Route path="/portafolio" element={<Portafolio />} />
      <Route path="/construccion" element={<Pageconstrucctor />} />
      <Route path="/vpshost" element={<Vpshost />} />
      <Route path="/Paypal" element={<Paypal />} />
      <Route path="/PlanVPS" element={<Planvps />} />
      <Route path="/Catalogocloud" element={<Catalogo />} />
      <Route path="/Portal360" element={<Portal360 />} />
      <Route path="/Plancpanel" element={<Plancpanel />} />
      <Route path="/ServerDedicated" element={<ServerDedicated />} />
      <Route path="/Venta-Importacion" element={<VIIT />} />
      <Route path="/QRpayment" element={<QRPayment />} />
      <Route path="/vercat/:id" element={<Vercat />} />
      <Route path="/vercatlat/:id" element={<Vercat />} />
      <Route path="/blog/:id" element={<Viewblog />} />
      <Route path="/viewdatacat/:id" element={<Viewcatalogo />} />
      <Route path="/verareanoticias/:id" element={<Viewcatnoti/>} />
      <Route path="/Viewnoticia/:id" element={<Viewnoticia/>} />
      <Route path="/historia" element={<Historia />} />
      <Route path="/noticias" element={<Noticias />} />
      <Route path="/Servicio-balanza" element={<ServBB />} />
      <Route path="/games" element={<Games />} />
      <Route path="/promociones" element={<Promocionone />} />
      <Route path="/viewpromocatunic/:id" element={<Viewcatalogopromoview />} />
    </Routes>
  );
};

export default RoutesConfig;
